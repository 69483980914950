import * as Styled from './OxFeedback.styled';

import { EColors } from 'src/config/enums';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { EOxInputType, OxInput } from 'src/components/OxInput';
import React, { useContext, useState, useEffect, ChangeEvent } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import StarRatingComponent from 'react-star-rating-component';

import { EModalId } from 'src/components/OxHygene';
import { LayoutContext } from 'src/context/LayoutContext';
import { OxBackToTop } from 'src/components/OxBackToTop';
import { OxButton } from 'src/components/OxButton';
import { OxForm } from 'src/components/OxForm';
import { OxSelect } from 'src/components/OxSelect';
import { OxSuccess } from 'src/components/OxSuccess/OxSuccess';
import { OxTextArea } from 'src/components/OxTextArea';
import { feedbackCallBack } from 'src/services/api/feedback';
import { useBreakpoints } from 'src/hooks';
import { EAlertVariant } from 'src/context/AlertContext';
import { OnyxError } from 'src/helpers/OnyxError';
import { WindowLocation } from '@reach/router';
import { OxCheckbox } from 'src/components/OxCheckbox';
import { useValidateResponse } from 'src/hooks/useValidateResponse';

type TProps = {
    location?: WindowLocation;
};

export const OxFeedback = ({ location }: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const layoutContext = useContext(LayoutContext);
    const devices = useBreakpoints();
    const [formData, setFormData] = useState({});
    const [isEmailPreFilled, setIsEmailPreFilled] = useState<boolean>(false);
    const [formSubmited, setFormSubmited] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const data = useStaticQuery(
        graphql`
            query {
                contactUsImage: file(relativePath: { eq: "feedback/feedback.png" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        `
    );
    const fluidImages = [
        {
            ...data.contactUsImage.childImageSharp.gatsbyImageData
        }
    ];

    const getParam = (param: string) => new URLSearchParams(location?.search).get(param);

    useEffect(() => {
        const email = getParam('email');
        if (email) {
            setIsEmailPreFilled(true);
            // URL queries use + as a space separator, because of this hubspot replaces + with a space
            // We want to trim space from either end then replace remaining space with + for emails
            // https://community.hubspot.com/t5/CMS-Development/URL-Encoding-issue-on-custom-url-with-email/td-p/258382
            setEmail(email.trim().replace(/\s+/g, '+'));
        }
    }, []);

    const starColor = EColors.Gold;
    const starHoverColor = EColors.Cameo;
    const emptyStarColor = EColors.GoldenLight;
    const starCount = 6;

    type TFeedbacks = {
        feedback_arrival: number;
        feedback_welcoming: number;
        feedback_pre_consult: number;
        feedback_knowledge: number;
        feedback_3d: number;
        feedback_medical: number;
        feedback_outcome: number;
        feedback_return: number;
        feedback_recommend: number;
    };

    const [feedback, setFeedback] = useState<TFeedbacks>({
        feedback_arrival: 1,
        feedback_welcoming: 1,
        feedback_pre_consult: 1,
        feedback_knowledge: 1,
        feedback_3d: 1,
        feedback_medical: 1,
        feedback_outcome: 1,
        feedback_return: 1,
        feedback_recommend: 1
    });

    const [feedbackHover, setFeedbackHover] = useState<Partial<TFeedbacks>>({});

    const [treatmentAttended, setTreatmentAttended] = useState<boolean>(false);

    const treatmentDoctors = [
        {
            name: 'Cleo Edwards',
            location: 'Dubai',
            value: 'nasser.madi@ouronyx.com'
        },
        {
            name: 'Daniel Hunt',
            location: 'London',
            value: 'daniel.hunt@ouronyx.com'
        },
        {
            name: 'Ameera Laher',
            location: 'London',
            value: 'ameera.laher@ouronyx.com'
        },
        {
            name: 'Marco Nicoloso',
            location: 'London',
            value: 'marco.nicoloso@ouronyx.com'
        },
        {
            name: 'Nasser Madi',
            location: 'Dubai',
            value: 'nasser.madi@ouronyx.com'
        },
        {
            name: 'Karim Sayed',
            location: 'Dubai',
            value: 'karim.sayed@ouronyx.com'
        },
        {
            name: 'Halah Taha',
            location: 'Dubai',
            value: 'halah.taha@ouronyx.com'
        }
    ].map((doctor) => ({
        label: `${doctor.name} (${doctor.location} Clinic)`,
        ...doctor
    }));

    const updateFeedback = (key: string, val: number): void =>
        setFeedback((prev) => ({ ...prev, [key]: val }));

    const updateFeedbackHover = (key: string, val?: number): void =>
        setFeedbackHover((prev) => ({ ...prev, [key]: val }));

    const starRatings: {
        label: string;
        name: string;
        basedOnTreatment?: boolean;
    }[] = [
        {
            label: "How would you rate your arrival experience in Ouronyx's space?",
            name: 'feedback_arrival'
        },
        {
            label: 'How welcoming and friendly were our staff?',
            name: 'feedback_welcoming'
        },
        {
            label: 'How would you rate the pre-consultation experience?',
            name: 'feedback_pre_consult'
        },
        {
            label: 'How knowledgeable was our advisor?',
            name: 'feedback_knowledge'
        },
        {
            label: 'How would you rate your 3D visualisation experience?',
            name: 'feedback_3d'
        },
        {
            label: 'How did you like the medical consultation with the doctor?',
            name: 'feedback_medical'
        },
        {
            label: 'How would you rate the outcome of your treatment?',
            name: 'feedback_outcome',
            basedOnTreatment: true
        },
        {
            label: 'How likely are you to return to Ouronyx?',
            name: 'feedback_return'
        },
        {
            label: 'How likely are you to recommend Ouronyx to a friend?',
            name: 'feedback_recommend'
        }
    ];

    const getSubmitRatingValue = (data: FormData, key: string): number => {
        const value = data.get(key);
        const feedbackItem = starRatings.find((rating) => rating.name === key);

        return !feedbackItem?.basedOnTreatment || treatmentAttended
            ? parseInt((value as string) ?? '0')
            : 0;
    };

    const onFormSubmit = async (data: FormData): Promise<void> => {
        const result = await feedbackCallBack({
            doctorName: data.get('doctor_name') as string,
            email: data.get('email') as string,
            feedbackArrival: getSubmitRatingValue(data, 'feedback_arrival'),
            feedbackWelcoming: getSubmitRatingValue(data, 'feedback_welcoming'),
            feedbackPreConsult: getSubmitRatingValue(data, 'feedback_pre_consult'),
            feedbackKnowledge: getSubmitRatingValue(data, 'feedback_knowledge'),
            feedback3d: getSubmitRatingValue(data, 'feedback_3d'),
            feedbackMedical: getSubmitRatingValue(data, 'feedback_medical'),
            feedbackOutcome: getSubmitRatingValue(data, 'feedback_outcome'),
            feedbackReturn: getSubmitRatingValue(data, 'feedback_return'),
            feedbackRecommend: getSubmitRatingValue(data, 'feedback_recommend'),
            feedbackComments: data.get('feedback_comments') as string
        });

        validateResponse(result);

        if (result) {
            if (result.errors || result.status === 'error') {
                if (Array.isArray(result.errors) && result.errors[0]) {
                    if (result.errors[0].errorType === 'INVALID_EMAIL') {
                        throw new OnyxError({
                            type: EAlertVariant.Error,
                            header: 'ERROR ALERT',
                            title: 'Invalid email address',
                            message:
                                'The email address you entered appears to be invalid. Please check your details and try again, if the problem persists please contact us.'
                        });
                    } else {
                        throw new OnyxError({
                            type: EAlertVariant.Error,
                            header: 'ERROR ALERT',
                            title: 'Something went wrong',
                            message:
                                'Please check your details and try again, if the problem persists please contact us.'
                        });
                    }
                }
            } else {
                setFormData({
                    email: data.get('email'),
                    feedbackComments: data.get('feedback_comments')
                });
                setFormSubmited(true);
            }
        } else {
            throw new OnyxError({
                type: EAlertVariant.Error,
                header: 'ERROR ALERT',
                title: 'Something went wrong',
                message:
                    'Please check your details and try again, if the problem persists please contact us.'
            });
        }
    };

    return (
        <Styled.Container>
            <OxSuccess
                show={formSubmited}
                formData={formData}
                hideSummary={true}
                subtitle="Your feedback is appreciated and helps us deliver the best service possible."
                bottomText="If you would like to discuss your experience further, please do get in touch with us."
                type="feedback"
            />
            <Styled.ContactUsContainer isSuccess={formSubmited}>
                <Styled.Title>Client Feedback</Styled.Title>
                <Styled.BlockText>
                    We value your feedback and would appreciate it if you could complete the form
                    below to let us know about your experience with us.
                </Styled.BlockText>
                <Styled.ImageFormContainer>
                    <Styled.FormContainer>
                        <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                            <Styled.FormWrapper>
                                <OxForm id="feedback-form" onFormSubmit={onFormSubmit}>
                                    {({ submitButtonProps }: { submitButtonProps: any }) => (
                                        <>
                                            <OxSelect
                                                name="doctor_name"
                                                title="Who was your doctor?"
                                                labelName="label"
                                                valueName="value"
                                                items={treatmentDoctors}
                                                required
                                            />
                                            <OxInput
                                                value={email}
                                                type={
                                                    isEmailPreFilled
                                                        ? EOxInputType.Hidden
                                                        : EOxInputType.Email
                                                }
                                                onChange={(e: ChangeEvent) => {
                                                    const target = e.target as HTMLInputElement;
                                                    setEmail(target.value);
                                                }}
                                                name="email"
                                                placeholder="Email"
                                                required
                                            />

                                            <OxCheckbox
                                                label="I had a treatment"
                                                name="treatment_attended"
                                                shape="roundedSquare"
                                                checkStyle="alt"
                                                value="1"
                                                fullLeftInput
                                                checked={treatmentAttended}
                                                onClick={(): void => {
                                                    setTreatmentAttended((prev) => !prev);
                                                }}
                                            />

                                            {starRatings.map((starRating, index) => (
                                                <React.Fragment key={index}>
                                                    {(!starRating.basedOnTreatment ||
                                                        treatmentAttended) && (
                                                        <Styled.RatingLabel>
                                                            {starRating.label}&#8288;*
                                                            <Styled.RatingStart>
                                                                <StarRatingComponent
                                                                    name={starRating.name}
                                                                    starColor={
                                                                        feedbackHover[
                                                                            starRating.name
                                                                        ]
                                                                            ? starHoverColor
                                                                            : starColor
                                                                    }
                                                                    emptyStarColor={emptyStarColor}
                                                                    starCount={starCount}
                                                                    value={
                                                                        feedbackHover[
                                                                            starRating.name
                                                                        ] ??
                                                                        feedback[starRating.name]
                                                                    }
                                                                    onStarHover={(
                                                                        nextValue,
                                                                        _prevValue,
                                                                        _name
                                                                    ) =>
                                                                        updateFeedbackHover(
                                                                            starRating.name,
                                                                            nextValue
                                                                        )
                                                                    }
                                                                    onStarHoverOut={(
                                                                        _nextValue,
                                                                        _prevValue,
                                                                        _name
                                                                    ) =>
                                                                        updateFeedbackHover(
                                                                            starRating.name
                                                                        )
                                                                    }
                                                                    onStarClick={(
                                                                        nextValue,
                                                                        _prevValue,
                                                                        _name
                                                                    ) =>
                                                                        updateFeedback(
                                                                            starRating.name,
                                                                            nextValue
                                                                        )
                                                                    }
                                                                />
                                                            </Styled.RatingStart>
                                                        </Styled.RatingLabel>
                                                    )}
                                                </React.Fragment>
                                            ))}

                                            <OxThemedInput theme={EInputTheme.GoldTransparent}>
                                                <OxTextArea
                                                    placeholder="Comments & Suggestions*"
                                                    name="feedback_comments"
                                                    id={`in_query`}
                                                    maxLength={500}
                                                    required
                                                />
                                            </OxThemedInput>
                                            <Styled.Checkbox
                                                label="* I agree to the full"
                                                labelLinkTitle="terms and conditions"
                                                labelLinkOnClick={(): void =>
                                                    layoutContext.setModalId(EModalId.Terms)
                                                }
                                                id="terms_checkbox"
                                                name="terms"
                                                value="terms"
                                                shape="roundedSquare"
                                                required
                                            />
                                            <OxThemedInput theme={EInputTheme.BackgroundGold}>
                                                <OxButton type="submit" icon {...submitButtonProps}>
                                                    SUBMIT
                                                </OxButton>
                                            </OxThemedInput>
                                        </>
                                    )}
                                </OxForm>
                            </Styled.FormWrapper>
                        </OxThemedInput>
                    </Styled.FormContainer>
                    <Styled.CustomImage fluidImages={fluidImages} />
                </Styled.ImageFormContainer>
            </Styled.ContactUsContainer>
            {(devices.xs || devices.s) && <OxBackToTop />}
        </Styled.Container>
    );
};
