import { EApiEndpoint } from 'src/config/enums';
import { apiPostCall } from 'src/services/api/api.core';

export const feedbackCallBack = async (data: {
    doctorName?: string;
    email?: string;
    feedbackArrival?: number;
    feedbackWelcoming?: number;
    feedbackPreConsult?: number;
    feedbackKnowledge?: number;
    feedback3d?: number;
    feedbackMedical?: number;
    feedbackOutcome?: number;
    feedbackReturn?: number;
    feedbackRecommend?: number;
    feedbackComments?: string;
}): Promise<any> => {
    const {
        doctorName,
        email,
        feedbackArrival,
        feedbackWelcoming,
        feedbackPreConsult,
        feedbackKnowledge,
        feedback3d,
        feedbackMedical,
        feedbackOutcome,
        feedbackReturn,
        feedbackRecommend,
        feedbackComments
    } = data;
    return await apiPostCall(
        EApiEndpoint.FormsPractitionerFeedback,
        JSON.stringify({
            doctorName,
            email,
            comments: feedbackComments,
            scores: {
                arrival: feedbackArrival,
                welcoming: feedbackWelcoming,
                preConsult: feedbackPreConsult,
                knowledge: feedbackKnowledge,
                visualisation: feedback3d,
                medical: feedbackMedical,
                outcome: feedbackOutcome,
                returnLikelihood: feedbackReturn,
                recommendLikelihood: feedbackRecommend
            }
        }),
        {
            alwaysRespond: true
        }
    );
};
